/*
 * @Author: your name
 * @Date: 2020-09-25 10:17:03
 * @LastEditTime: 2021-04-09 09:53:51
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/utils/service.js
 */
import axios from 'axios';
import store from '@/store';
import { Toast } from 'vant';
import serverconfig from '@/api/server.config';
console.log(serverconfig);
let HALL_WECHAT_API = serverconfig.API;
// create an axios instance
const service = axios.create({
  baseURL: HALL_WECHAT_API,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

let pendings = []; // 用于存储每个ajax请求
let CancleToken = axios.CancelToken;

let removePending = (e) => {
  pendings.map((item, index) => {
    if (item.u === e.url + '&' + e.method) {
      item.f();
      pendings.splice(index, 1);
    }
  });
};

/**
 * 阻止重复请求
 * strUrl: 拼接的methos+url
 */

// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    removePending(config); // 先取消上次请求

    config.cancleToken = new CancleToken((c) => {
      pendings.push({ u: config.url + '&' + config.method, f: c });
    });
    // 不传递默认开启loading
    if (!config.hideloading) {
      // loading
      Toast.loading({
        forbidClick: true,
      });
    }
    if (store.getters.userInfo) {
      // config.headers['token'] = store.getters.token;
      config.headers['Authorization'] = store.getters.userInfo.token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error);
    // return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config);
    Toast.clear();
    const res = response.data;
    // 这里注意修改成你访问的服务端接口规则
    if (res.status && res.status !== 'complete') {
        if (res.errorMessage.indexOf('AccountChargeMeter#invoicePreview')  > -1) {
            res.status = 'complete';
            return res;
        } else {
            Toast({ message: res.errorMessage });
        }

      // 登录超时,重新登录
      if (res.status === 401) {
        store.dispatch('user/Logout').then(() => {
          location.reload();
        });
      }
      return Promise.reject(res || 'error');
    }
    // else {
    //   return Promise.resolve(res);
    // }
    return res;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('user/Logout').then(() => {
          location.reload();
        });
      }
    }
    Toast.clear();
    return Promise.reject(error);
  }
);

export default service;
