import Vue from 'vue';

import './core/lazy_use';
import App from './App.vue';
import router from './router';
import store from './store';
import './permission';
import { getWxApplets } from '@/plugins/auth';
Vue.config.productionTip = false;
Vue.prototype.$getWxApplets = getWxApplets;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
// Vue.use(require('vue-wechat-title'))
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
