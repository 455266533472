/*
 * @Author: yangliao
 * @Date: 2020-11-11 16:21:10
 * @LastEditTime: 2021-06-07 09:44:28
 * @LastEditors: yangliao
 * @Description: 数据字典
 * @FilePath: /netHallOfficialAccounts/src/store/modules/dict.js
 */
import storage from '@/utils/storage';
import { getAllDict } from '@/api/components';
import { selectDictEnumByCode } from '@/api/home';

export default {
  namespaced: true,
  state: {
    dictory: storage.get('dictory'), // 词语
    dictoryBycode_STATUS_CODE: storage.get('dictoryStatusCode'),
    dictoryBycode_BUS_CODE: storage.get('dictoryBusCode'),
    businessType: 'BusinessType', // 业务类型
    businessManagement: 'BusinessManagement', // 业务办理
    Feedback: 'Feedback', // 反馈业务
    PlatformSource: 'PlatformSource' // 平台来源
  },
  getters: {},
  mutations: {
    SET_DICTORY: (state, dictory) => {
      state.dictory = dictory;
    },
    SET_DICTORY_BYCODE_STATUS_CODE: (state, dictoryBycode_STATUS_CODE) => {
      state.dictoryBycode_STATUS_CODE = dictoryBycode_STATUS_CODE;
    },
    SET_DICTORY_BYCODE_BUS_CODE: (state, dictoryBycode_BUS_CODE) => {
      state.dictoryBycode_BUS_CODE = dictoryBycode_BUS_CODE;
    }
  },
  actions: {
    GenerateDictory({ commit }) {
      return new Promise((resolve, reject) => {
        getAllDict('nethall')
          .then((res) => {
            let { resultData } = res;
            let data = {};
            resultData.forEach((item) => {
              let code = item.code;
              data[code] = item;
            });
            storage.set('dictory', data);
            commit('SET_DICTORY', data);
            resolve(data);
          })
          .catch((e) => {
            reject(new Error('获取数据字典失败'));
          });
      });
    },
    getSelectDictEnumByCode({ commit }, params) {
      return new Promise((resolve, reject) => {
        selectDictEnumByCode(params)
          .then((res) => {
            if (res.status === 'complete') {
              let resultData = res.resultData;
              storage.set('dictoryStatusCode', resultData);
              commit('SET_DICTORY_BYCODE_STATUS_CODE', resultData);
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getSelectDictEnumByBusCode({ commit }, params) {
      return new Promise((resolve, reject) => {
        selectDictEnumByCode(params)
          .then((res) => {
            if (res.status === 'complete') {
              let resultData = res.resultData;
              storage.set('dictoryBusCode', resultData);
              commit('SET_DICTORY_BYCODE_BUS_CODE', resultData);
              resolve(res);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
