/*
 * @Author: yangliao
 * @Date: 2020-11-28 17:13:08
 * @LastEditTime: 2021-06-01 17:31:44
 * @LastEditors: yangliao
 * @Description: 业务办理模块
 * @FilePath: /netHallOfficialAccounts/src/router/business.js
 */

//业务办理
const BusinessStep = () => import('@/views/Business/components/BusinessStep');
const Expand = () => import('@/views/Business/Expand');
const InstallWater = () => import('@/views/Business/Expand/InstallWater');
const SubsistenceNotice = () => import('@/views/Business/Subsistence');
const Subsistence = () => import('@/views/Business/Subsistence/SubsistenceAllowances');
const Transfer = () => import('@/views/Business/Transfer');
const WaterTransfer = () => import('@/views/Business/Transfer/WaterTransfer');
const RepairNotice = () => import('@/views/Business/Repair');
const Repair = () => import('@/views/Business/Repair/Repair');
const PopulationNotice = () => import('@/views/Business/Population');
const Population = () => import('@/views/Business/Population/Population');
const WaterStopNotice = () => import('@/views/Business/WaterStop');
const WaterStop = () => import('@/views/Business/WaterStop/WaterStop');
const WaterResumeNotice = () => import('@/views/Business/WaterResume');
const WaterResume = () => import('@/views/Business/WaterResume/WaterResume');
const WaterCloseNotice = () => import('@/views/Business/WaterClose');
const WaterClose = () => import('@/views/Business/WaterClose/WaterClose');
const WaterpipeMoveNotice = () => import('@/views/Business/WaterpipeMove');
const WaterpipeMove = () => import('@/views/Business/WaterpipeMove/WaterpipeMove');
const WaterMeterMoveNotice = () => import('@/views/Business/WaterMeterMove');
const WaterMeterMove = () => import('@/views/Business/WaterMeterMove/WaterMeterMove');
const RenamedNotice = () => import('@/views/Business/Renamed');
const WaterRenamed = () => import('@/views/Business/Renamed/WaterRenamed');
const NewsTurnNotice = () => import('@/views/Business/NewsTurn');
const WaterNewsTurn = () => import('@/views/Business/NewsTurn/WaterNewsTurn');
const NatureTurnNotice = () => import('@/views/Business/NatureTurn');
const WaterNatureTurn = () => import('@/views/Business/NatureTurn/WaterNatureTurn');

const businessRouter = [
  //业务办理
  {
    path: '/Business/BusinessStep',
    name: 'BusinessStep',
    component: BusinessStep,
    meta: {
      title: '办理进度',
    },
  },
  {
    path: '/Business/Expand',
    name: 'Expand',
    component: Expand,
    meta: {
      title: '新户开通须知',
    },
  },
  {
    path: '/Expand/InstallWater',
    name: 'InstallWater',
    component: InstallWater,
    meta: {
      title: '新户开通',
    },
  },
  {
    path: '/Business/Transfer',
    name: 'Transfer',
    component: Transfer,
    meta: {
      title: '更名过户须知',
    },
  },
  {
    path: '/Business/WaterTransfer',
    name: 'WaterTransfer',
    component: WaterTransfer,
    meta: {
      title: '更名过户',
    },
  },
  {
    path: '/Business/RepairNotice',
    name: 'RepairNotice',
    component: RepairNotice,
    meta: {
      title: '报事报修须知',
    },
  },
  {
    path: '/Business/Repair',
    name: 'Repair',
    component: Repair,
    meta: {
      title: '报事报修',
    },
  },
  {
    path: '/Business/PopulationNotice',
    name: 'PopulationNotice',
    component: PopulationNotice,
    meta: {
      title: '阶梯人口须知',
    },
  },
  {
    path: '/Business/Population',
    name: 'Population',
    component: Population,
    meta: {
      title: '阶梯人口',
    },
  },
  {
    path: '/Business/SubsistenceNotice',
    name: 'SubsistenceNotice',
    component: SubsistenceNotice,
    meta: {
      title: '低保申请须知',
    },
  },
  {
    path: '/Business/Subsistence',
    name: 'Subsistence',
    component: Subsistence,
    meta: {
      title: '低保申请',
    },
  },
  {
    path: '/Business/WaterStopNotice',
    name: 'WaterStopNotice',
    component: WaterStopNotice,
    meta: {
      title: '水表报停须知',
    },
  },
  {
    path: '/Business/WaterStop',
    name: 'WaterStop',
    component: WaterStop,
    meta: {
      title: '水表报停',
    },
  },
  {
    path: '/Business/WaterResumeNotice',
    name: 'WaterResumeNotice',
    component: WaterResumeNotice,
    meta: {
      title: '水表复装须知',
    },
  },
  {
    path: '/Business/WaterResume',
    name: 'WaterResume',
    component: WaterResume,
    meta: {
      title: '水表复装',
    },
  },
  {
    path: '/Business/WaterCloseNotice',
    name: 'WaterCloseNotice',
    component: WaterCloseNotice,
    meta: {
      title: '销户须知',
    },
  },
  {
    path: '/Business/WaterClose',
    name: 'WaterClose',
    component: WaterClose,
    meta: {
      title: '销户',
    },
  },
  {
    path: '/Business/WaterpipeMoveNotice',
    name: 'WaterpipeMoveNotice',
    component: WaterpipeMoveNotice,
    meta: {
      title: '移管申请须知',
    },
  },
  {
    path: '/Business/WaterpipeMove',
    name: 'WaterpipeMove',
    component: WaterpipeMove,
    meta: {
      title: '移管申请',
    },
  },
  {
    path: '/Business/WaterMeterMoveNotice',
    name: 'WaterMeterMoveNotice',
    component: WaterMeterMoveNotice,
    meta: {
      title: '水表迁移须知',
    },
  },
  {
    path: '/Business/WaterMeterMove',
    name: 'WaterMeterMove',
    component: WaterMeterMove,
    meta: {
      title: '水表迁移',
    },
  },
  {
    path: '/Business/RenamedNotice',
    name: 'RenamedNotice',
    component: RenamedNotice,
    meta: {
      title: '更名须知',
    },
  },
  {
    path: '/Business/WaterRenamed',
    name: 'WaterRenamed',
    component: WaterRenamed,
    meta: {
      title: '更名',
    },
  },
  {
    path: '/Business/NewsTurnNotice',
    name: 'NewsTurnNotice',
    component: NewsTurnNotice,
    meta: {
      title: '开票信息变更须知',
    },
  },
  {
    path: '/Business/WaterNewsTurn',
    name: 'WaterNewsTurn',
    component: WaterNewsTurn,
    meta: {
      title: '开票信息变更',
    },
  },
  {
    path: '/Business/NatureTurnNotice',
    name: 'NatureTurnNotice',
    component: NatureTurnNotice,
    meta: {
      title: '用水性质变更须知',
    },
  },
  {
    path: '/Business/WaterNatureTurn',
    name: 'WaterNatureTurn',
    component: WaterNatureTurn,
    meta: {
      title: '用水性质变更',
    },
  },
];

export default businessRouter;
