import { getWeChatVerification, createJsApiSignature } from '@/api/user';
import serverconfig from '@/api/server.config';
import wx from 'weixin-js-sdk';
//获取微信配置信息--跳转小程序、获取定位信息
export async function getWxApplets(href, _this, shareFlag) {
  href = href.split('#')[0];
  // getWeChatVerification(serverconfig.WECHAT_APPID).then((res) => {
  // });
  await createJsApiSignature({ appId: serverconfig.WECHAT_APPID, pageUrl: href })
    .then((res) => {
      wx.config({
        debug: false,
        appId: res.resultData.appId,
        timestamp: res.resultData.timestamp,
        nonceStr: res.resultData.nonceStr,
        signature: res.resultData.signature,
        jsApiList: [
          'wx-open-launch-weapp',
          'chooseImage',
          'previewImage',
          'getLocation',
          'openLocation',
          'onMenuShareTimeline',
          'showMenuItems',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'updateAppMessageShareData', // 分享给好友
          'updateTimelineShareData', // 分享到朋友圈
        ], //跳转小程序、获取定位信息、导航
        openTagList: ['wx-open-launch-weapp'], //打开的标签名
      });
      wx.ready(function() {
        console.log('ready');
        wx.checkJsApi({
          jsApiList: ['showMenuItems', 'updateAppMessageShareData', 'updateTimelineShareData'],
          success: function (res) {
            wx.showMenuItems({
              menuList: [
                'menuItem:share:appMessage', // 发送给朋友
                'menuItem:share:timeline', // 分享到朋友圈
                'menuItem:favorite' // 收藏
              ]
            })
          }
        })
      });
      wx.error(function(err) {
        console.log(err);
      });
    })
    .catch((error) => {
      console.log(error);
    });
}
