/*
 * @Author: your name
 * @Date: 2020-09-25 10:04:43
 * @LastEditTime: 2020-12-15 09:26:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/store/getters.js
 */
const getters = {
    // user
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    loginStatus: state => state.user.loginStatus,
    homeInfo: state => state.user.homeInfo,
    dictory: state => state.dict.dictory,
    businessNews: state => state.user.businessNews,
    dictoryBycode_STATUS_CODE: state => state.dict.dictoryBycode_STATUS_CODE,
    dictoryBycode_BUS_CODE: state => state.dict.dictoryBycode_BUS_CODE
  };

  export default getters;
