/*
 * @Author: chenzh
 * @Date: 2020-09-25 08:26:30
 * @LastEditTime: 2021-04-29 11:42:46
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/router/index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import serverconfig from '@/api/server.config';
const ROUTER_BASE = serverconfig.ROUTER_BASE;
import businessRouter from './business';
import { signRedirect } from '@/api/contract';

const Home = () => import('@/views/Home');

// 立即绑定
const Bind = () => import('@/views/Bind');
const HouseholderInfo = () => import('@/views/Bind/householderInfo');
const SelectBindAccount = () => import('@/views/Bind/selectBindAccount');

const QueryPay = () => import('@/views/QueryPay');
const FeeDetails = () => import('@/views/QueryPay/FeeDetails');
const PaymentSuccess = () => import('@/views/QueryPay/PaymentSuccess');
const PaymentPage = () => import('@/views/QueryPay/PaymentPage');
const PaymentResult = () => import('@/views/QueryPay/PaymentResult');

const WaterAccount = () => import('@/views/WaterAccount');
const PreStorage = () => import('@/views/WaterAccount/PreStorage');
const BindCustomer = () => import('@/views/WaterAccount/bindCustomer');
const RechargeRecord = () => import('@/views/WaterAccount/RechargeRecord');

// 电子发票
const Invoice = () => import('@/views/Invoice');
const InvoiceSetting = () => import('@/views/Invoice/invoiceSetting');
const InvoiceBill = () => import('@/views/Invoice/invoiceBill');
// 我的办理
const BusinessManagement = () => import('@/views/BusinessManagement');
// 自助抄表
const SelfMeterRead = () => import('@/views/SelfMeterRead');
const MeterReadingRecord = () => import('@/views/SelfMeterRead/meterReadingRecord');
const SelfMeterReadImmediately = () => import('@/views/SelfMeterRead/selfMeterReadImmediately');
// 用水统计
const WaterStatistics = () => import('@/views/WaterStatistics');
const SelectWaterUser = () => import('@/views/SelectWaterUser');
//反馈
const ViolationReport = () => import('@/views/Business/Feedback/ViolationReport');
const Proposal = () => import('@/views/Business/Feedback/Proposal');
const RepairApply = () => import('@/views/Business/Feedback/RepairApply');
const MyFeedBack = () => import('@/views/Feedback/MyFeedBack');

//合同
const MyContract = () => import('@/views/Contract/MyContract');
const Block = () => import('@/views/Contract/Block');

// 用户登录
const UserLogin = () => import('@/views/UserLogin');
const Setting = () => import('@/views/Setting');
const ChangeCurrentPhone = () => import('@/views/Setting/changeCurrenrPhone');
const ChangeNewPhone = () => import('@/views/Setting/changeNewPhone');
const Notice = () => import('@/views/Setting/notice');
// 认证
const CertifiedPage = () => import('@/views/CertifiedPage');
const CardCertified = () => import('@/views/CertifiedPage/CardCertified');
const CardCertifiedList = () => import('@/views/CertifiedPage/CardCertifiedList');
const CertifiedContract = () => import('@/views/CertifiedPage/CertifiedContract');

// 公众信息
const BusinessOffice = () => import('@/views/PublicNews/BusinessOffice');

const charge = () => import('@/views/QueryPay/module/charge');

// 用水公告
const NewsNotice = () => import('@/views/NewsNotice/NewsList')
const NewsDetail = () => import('@/views/NewsNotice/NewsDetail')

// const pdf = () => import('@/views/Invoice/module/pdf');

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '掌上营业厅',
    },
  },
  /* 立即绑定 */
  {
    path: '/Bind',
    name: 'Bind',
    component: Bind,
    meta: {
      title: '用户绑定',
    },
  },
  {
    path: '/Bind/householderInfo',
    name: 'HouseholderInfo',
    component: HouseholderInfo,
    meta: {
      title: '用户绑定',
    },
  },
  {
    path: '/Bind/selectBindAccount',
    name: 'SelectBindAccount',
    component: SelectBindAccount,
    meta: {
      title: '选择绑定账号',
    },
  },
  /* 快捷缴费 */
  {
    path: '/QueryPay',
    name: 'QueryPay',
    component: QueryPay,
    meta: {
      title: '快捷缴费',
    },
  },
  /* 费用明细 */
  {
    path: '/QueryPay/FeeDetails',
    name: 'FeeDetails',
    component: FeeDetails,
    meta: {
      title: '未缴账单详情',
    },
  },
  {
    path: '/QueryPay/PaymentSuccess',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    meta: {
      title: '支付结果',
    },
  },
  {
    path: '/QueryPay/PaymentPage',
    name: 'PaymentPage',
    component: PaymentPage,
    meta: {
      title: '缴费',
    },
  },
  {
    path: '/QueryPay/PaymentResult',
    name: 'PaymentResult',
    component: PaymentResult,
    meta: {
      title: '支付结果',
    },
  },
  {
    path: '/WaterAccount',
    name: 'WaterAccount',
    component: WaterAccount,
    meta: {
      title: '用水账户',
    },
  },
  {
    path: '/WaterAccount/PreStorage',
    name: 'PreStorage',
    component: PreStorage,
    meta: {
      title: '充值缴费',
    },
  },
  {
    path: '/WaterAccount/RechargeRecord',
    name: 'RechargeRecord',
    component: RechargeRecord,
    meta: {
      title: '缴费记录',
    },
  },
  {
    path: '/WaterAccount/bindCustomer',
    name: 'BindCustomer',
    component: BindCustomer,
    meta: {
      title: '绑定客户',
    },
  },

  /* 发票 */
  {
    path: '/Invoice',
    name: 'Invoice',
    component: Invoice,
    meta: {
      title: '电子发票',
    },
  },
  {
    path: '/Invoice/Bill',
    name: 'InvoiceBill',
    component: InvoiceBill,
    meta: {
      title: '电子发票信息',
    },
  },
  {
    path: '/Invoice/invoiceSetting',
    name: 'InvoiceSetting',
    component: InvoiceSetting,
    meta: {
      title: '开票设置',
    },
  },
  {
    path: '/WaterStatistics',
    name: 'WaterStatistics',
    component: WaterStatistics,
    meta: {
      title: '用水统计',
    },
  },
  /* 我的办理 */
  {
    path: '/BusinessManagement',
    name: 'BusinessManagement',
    component: BusinessManagement,
    meta: {
      title: '我的办理',
    },
  },
  {
    path: '/SelfMeterRead',
    name: 'SelfMeterRead',
    component: SelfMeterRead,
    meta: {
      title: '自助抄表',
    },
  },
  {
    path: '/SelfMeterRead/meterReadingRecord',
    name: 'MeterReadingRecord',
    component: MeterReadingRecord,
    meta: {
      title: '抄表记录',
    },
  },
  {
    path: '/SelfMeterRead/selfMeterReadImmediately',
    name: 'SelfMeterReadImmediately',
    component: SelfMeterReadImmediately,
    meta: {
      title: '自助抄表',
    },
  },
  {
    path: '/SelectWaterUser',
    name: 'SelectWaterUser',
    component: SelectWaterUser,
    meta: {
      title: '选择用户',
    },
  },
  //业务办理
  ...businessRouter,
  //反馈模块
  {
    path: '/Feedback/ViolationReport',
    name: 'ViolationReport',
    component: ViolationReport,
    meta: {
      title: '违章举报',
    },
  },
  {
    path: '/Feedback/Proposal',
    name: 'Proposal',
    component: Proposal,
    meta: {
      title: '意见反馈',
    },
  },
  {
    path: '/Feedback/RepairApply',
    name: 'RepairApply',
    component: RepairApply,
    meta: {
      title: '报事报修',
    },
  },
  {
    path: '/Feedback/MyFeedBack',
    name: 'MyFeedBack',
    component: MyFeedBack,
    meta: {
      title: '我的反馈',
    },
  },
  {
    path: '/Contract/MyContract',
    name: 'MyContract',
    component: MyContract,
    meta: {
      title: '我的合同',
    },
  },
  {
    path: '/blockOperate',
    name: 'block',
    component: Block,
  },
  {
    path: '/UserLogin',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: '用户登录',
    },
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: Setting,
    meta: {
      title: '设置',
    },
  },
  {
    path: '/Setting/changeCurrentPhone',
    name: 'ChangeCurrentPhone',
    component: ChangeCurrentPhone,
    meta: {
      title: '更换当前手机号',
    },
  },
  {
    path: '/Setting/ChangeNewPhone',
    name: 'ChangeNewPhone',
    component: ChangeNewPhone,
    meta: {
      title: '更换当前手机号',
    },
  },
  {
    path: '/Setting/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      title: '停水复水通知',
    },
  },
  {
    path: '/CertifiedContract',
    name: 'CertifiedContract',
    component: CertifiedContract,
    meta: {
      title: '用户认证',
    },
  },
  {
    path: '/CertifiedPage',
    name: 'CertifiedPage',
    component: CertifiedPage,
    meta: {
      title: '用户认证',
    },
  },
  {
    path: '/CertifiedPage/CardCertified',
    name: 'CardCertified',
    component: CardCertified,
    meta: {
      title: '用户认证',
    },
  },
  {
    path: '/CertifiedPage/CardCertifiedList',
    name: 'CardCertifiedList',
    component: CardCertifiedList,
    meta: {
      title: '认证',
    },
  },
  {
    path: '/PublicNews/BusinessOffice',
    name: 'BusinessOffice',
    component: BusinessOffice,
    meta: {
      title: '营业水厂',
    },
  },
  {
    path: '/charge',
    name: 'charge',
    component: charge,
    meta: {
      title: '缴费',
    },
  },
  // {
  //   path: '/weChat/pdf',
  //   name: 'pdf',
  //   component: pdf,
  //   meta: {
  //     title: '文件预览',
  //   },
  // },
  
  {
    path: '/NewsNotice/NewsList',
    name: 'NewsNotice',
    component: NewsNotice,
    meta: {
      title: '首创供水',
    },
  },
  {
    path: '/NewsNotice/NewsDetail',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      title: '',
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/block') {
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    let res = await signRedirect(to.query.businessFlowId);
    next({ path: '/blockOperate', query: { ...res, businessFlowId: to.query.businessFlowId } });
  } else {
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    next();
  }
});

export default router;
