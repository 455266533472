/*
 * @Author: your name
 * @Date: 2020-09-28 10:44:30
 * @LastEditTime: 2021-06-03 10:40:25
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/permission.js
 */
import qs from 'qs';
import router from '@/router';
import store from '@/store';
import wechatAuth from '@/plugins/vueWechatAuthPlugin';
import { Toast } from 'vant';
import serverconfig from '@/api/server.config';
const WECHAT_APPID = serverconfig.WECHAT_APPID;
const WECHAT_AUTH_URL = serverconfig.WECHAT_AUTH_URL;
// 设置APPID
wechatAuth.setAppId(WECHAT_APPID);

const whiteList = ['/404'];

async function checkUserBind(to, from, next) {
  next();
  // if (process.env.NODE_ENV !== 'development' && router.mode === 'hash') {
  // window.location.href =
  //     window.location.origin + window.location.pathname + window.location.hash;
  // } else {
  //   next();
  // }
}

router.beforeEach(async (to, from, next) => {
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) {
    return next();
  }
  const { loginStatus } = store.getters;
  switch (Number(loginStatus)) {
    case 0:
      // 获取跳转地址
      wechatAuth.redirect_uri = processUrl();
      await store.dispatch('user/setLoginStatus', 1);
      window.location.href = wechatAuth.authUrl;

      break;
    case 1:
      try {
        wechatAuth.returnFromWechat(window.location.href);
        const code = wechatAuth.code;
        // 通过code换取token
        // await store.dispatch('user/loginWechatAuth', code); // 原始版本
        let arg = {
          wxCode: code,
          appId: WECHAT_APPID
        };
        await store.dispatch('user/loginWechatAuth', arg);
        await store.dispatch('user/setLoginStatus', 2);

        checkUserBind(to, from, next);
        // hash
        // if (process.env.NODE_ENV !== 'development' && router.mode === 'hash') {
        //   window.location.href =
        //     window.location.origin + window.location.pathname + window.location.hash;
        // } else {
        //   next();
        // }
      } catch (err) {
        await store.dispatch('user/setLoginStatus', 0);
        Toast.fail('获取用户信息失败!');
      }
      break;
    case 2:
      checkUserBind(to, from, next);
      break;
    default:
      break;
  }
});

/**
 * 处理url链接
 * @returns {string}
 */
function processUrl() {
  // 本地环境换通过auth.html拿code
  if (process.env.NODE_ENV === 'development') {
    // 中间授权页地址
    return `${WECHAT_AUTH_URL}?backUrl=${window.location.href.split('?')[0]}`;
  }
  const url = window.location.href;
  // 解决多次登录url添加重复的code与state问题
  const urlParams = qs.parse(url.split('?')[1]);
  let redirectUrl = url;
  if (urlParams.code && urlParams.state) {
    delete urlParams.code;
    delete urlParams.state;
    const query = qs.stringify(urlParams);
    if (query.length) {
      redirectUrl = `${url.split('?')[0]}?${query}`;
    } else {
      redirectUrl = `${url.split('?')[0]}`;
    }
  }
  return redirectUrl;
}
