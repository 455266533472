/*
 * @Author: your name
 * @Date: 2020-09-23 15:11:15
 * @LastEditTime: 2021-04-08 17:13:43
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/api/home.js
 */

// import request from '@/utils/request';
// import request from '@/utils/requestWPG';
import  qs from 'qs'
import request from '@/utils/service';
import serverconfig from "./server.config";
let paymentapi = serverconfig.PAYMENT_API;
let URLAPI = serverconfig.URLAPI;

export function selectAreaWaterVolume(params) {
  return request({
    url: `/admin/waterVolumeReport/selectAreaWaterVolume`,
    method: 'post',
    data: params,
  });
}

// ---------- 以上示例 可删除 ----------
export function getCardTypes(params) {
  return request({
    url: `/esign/getCardTypes`,
    method: 'post',
  });
}
export function certifyAll(params) {
  return request({
    url: `/custRealInfo/certifyAll`,
    method: 'post',
    data: params,
  });
}
// 掌上营业厅---首页
export function selectByCustRealId(params) {
  return request({
    url: `/custRealInfo/selectByCustRealId`,
    method: 'post',
    data: params,
  });
}

// 掌上营业厅---我的用水
export function selectMyWater(params) {
  return request({
    url: `/waterStatistics/selectMyWater`,
    method: 'post',
    data: params,
  });
}

// 用水统计
export function selectWaterStatistics(params) {
  return request({
    url: `/waterStatistics/selectWaterStatistics`,
    method: 'post',
    data: params,
  });
}
// 用水统计 --- 某个用水账户的用水统计
export function selectOneWaterStatistics(params) {
  return request({
    url: `/waterStatistics/selectOneWaterStatistics/${params}`,
    method: 'get',
  });
}
// 用水统计---账户筛选
export function selectWuserNoCust(params) {
  return request({
    url: `/custWaterUserRel/selectWuserNoCust`,
    method: 'post',
    data: params,
  });
}
// 客户用水户绑定表 --- 修改标签
export function updateLabel(params) {
  return request({
    url: `/custWaterUserRel/updateLabel`,
    method: 'post',
    data: params,
  });
}
// 客户用水户绑定表 --- 解除绑定
export function unbindUser(params, params2) {
  return request({
    url: `/custWaterUserRel/unbindUser/${params}/${params2}`,
    method: 'put',
    // data: params
  });
}
// 电子发票 --- 未开发票
export function selectNotInvoiced(params) {
  return request({
    url: `/electronicInvoices/selectNotInvoiced`,
    method: 'post',
    data: params,
  });
}
// 电子发票 --- 已开发票
export function selectInvoiced(params) {
  return request({
    url: `/electronicInvoices/selectInvoiced`,
    method: 'post',
    data: params,
  });
}
// 缴费记录查询（电子发票信息）
export function selectReceivedRecords(params) {
  return request({
    url: `/electronicInvoices/selectReceivedRecords`,
    method: 'post',
    data: params,
  });
}
// 通过实收ID查询费用明细
export function selectRecordsById(id) {
  return request({
    url: `/electronicInvoices/selectRecordsById/${id}`,
    method: 'get',
  });
}
// 发票发送到邮箱
export function sendInvoice(params) {
  return request({
    url: `/electronicInvoices/sendInvoice`,
    method: 'post',
    data: params,
  });
}
// 电子发票开票
export function invoicePreview(params) {
  return request({
    url: `/electronicInvoices/invoicePreview`,
    method: 'post',
    data: params,
  });
}
// 电子发票---电子发票设置
export function invoiceSetting(params) {
  return request({
    url: `/electronicInvoices/invoiceSetting`,
    method: 'post',
    data: params,
  });
}
//  电子发票---显示电子发票设置
export function showInvoiceSetting(params) {
  return request({
    url: `/electronicInvoices/showInvoiceSetting`,
    method: 'post',
    data: params,
  });
}
// 电子发票---发送到邮箱
export function sendEmail(params) {
  return request({
    url: `/electronicInvoices/sendEmail`,
    method: 'post',
    data: params,
  });
}
// 电子发票 --- 账户筛选
export function selectAccount(params) {
  return request({
    url: `/electronicInvoices/selectAccount`,
    method: 'post',
    data: params,
  });
}
// 电子发票---我要开票
export function invoice(params) {
  return request({
    url: `/electronicInvoices/invoice`,
    method: 'post',
    data: params,
  });
}
// 电子发票---查询 该用户下 相关箱
export function findRelevantEmail(params) {
  return request({
    url: `/electronicInvoices/findRelevantEmail`,
    method: 'post',
    data: params,
  });
}
// 设置---通过id查询
export function selectCustRealInfoById(params) {
  return request({
    url: `/custRealInfo/selectCustRealInfoById`,
    method: 'post',
    data: params,
  });
}
// 设置---获取手机验证码
export function getCode(params) {
  return request({
    url: `/custRealInfo/getCode`,
    method: 'post',
    data: params,
  });
}
// 设置---校验手机 验证码 是否正确
export function checkCode(params) {
  return request({
    url: `/custRealInfo/checkCode`,
    method: 'post',
    data: params,
  });
}
// 设置---校验手机 验证码 是否正确
export function certifyByPhone(params) {
  return request({
    url: `/custRealInfo/certifyByPhone`,
    method: 'post',
    data: params,
  });
}
// 设置---提交 修改手机号码
export function updateMobile(params) {
  return request({
    url: `/custRealInfo/updateMobile`,
    method: 'post',
    data: params,
  });
}
// 设置---修改邮箱
export function updateEmail(params) {
  return request({
    url: `/custRealInfo/updateEmail`,
    method: 'post',
    data: params,
  });
}

// 设置---修改手机号
export function updatePhone(params) {
  return request({
    url: `/custRealInfo/updateTelephone`,
    method: 'post',
    data: params,
  });
}
// 已认证
export function getInfo(params) {
  return request({
    url: `/custRealInfo/getInfo`,
    method: 'post',
    data: params,
  });
}
// 设置---查看 停水复水通知 状态
export function showStopOrResumeNotice(params) {
  return request({
    url: `/custBusNotifySwitch/showStopOrResumeNotice`,
    method: 'post',
    data: params,
  });
}
// 设置---更改 停水复水通知 状态
export function updateNotice(params) {
  return request({
    url: `/custBusNotifySwitch/updateNotice`,
    method: 'post',
    data: params,
  });
}
// 立即绑定---通过用水户号绑定
export function bindUserByMeterCode(params) {
  return request({
    url: `/custWaterUserRel/bindUserByMeterCode`,
    method: 'post',
    data: params,
  });
}
// 立即绑定---通过证件号绑定
export function bindUserByIdNum(params) {
  return request({
    url: `/custWaterUserRel/bindUserByIdNum`,
    method: 'post',
    data: params,
  });
}
// 立即绑定---直接绑定
export function bindUserById(params) {
  return request({
    url: `/custWaterUserRel/bindUserById`,
    method: 'post',
    data: params,
  });
}
// 立即绑定---缴费单位
export function selectPaymentList(params) {
  return request({
    url: `/custWaterUserRel/selectPaymentList`,
    method: 'post',
    data: params,
  });
}
// 快捷缴费---分页查询
export function selectPayOrderPage(params) {
  return request({
    url: `/payOrder/selectPayOrderPage/${params}`,
    method: 'get',
  });
}
// 快捷缴费---一键缴费
export function paymentOrder(params) {
  return request({
    url: `/payOrder/paymentOrder`,
    method: 'post',
    data: params,
  });
}
// 快捷缴费---详情
export function selectByBill(params) {
  return request({
    url: `/payOrder/selectByBill`,
    method: 'post',
    data: params,
  });
}
// 快捷缴费---查看已交账单详情
export function selectPaidBill(params) {
  return request({
    url: `/payOrder/selectPaidBill`,
    method: 'post',
    data: params,
  });
}
// 快捷缴费---查看已交账单详情【候补】
export function selectBillByThird(params) {
  return request({
    url: `/payOrder/selectBillByThird`,
    method: 'post',
    data: params,
  });
}
// 自助抄表---根据户号查询订单【单个缴费页面】
export function selectByWaterUserNo(params) {
  return request({
    url: `/payOrder/selectByWaterUserNo/${params}`,
    method: 'get',
  });
}
// 充值缴费 ---页面显示
export function selectwuser(params) {
  return request({
    url: `/rechargeOrder/selectwuser`,
    method: 'get',
  });
}
// 预存种植 --- 单个页面显示
export function selectOneWUser(params) {
  return request({
    url: `/rechargeOrder/selectOneWUser/${params}`,
    method: 'get',
  });
}
// 充值缴费-- 点击事件
export function paymentRecharge(params) {
  return request({
    url: `/rechargeOrder/paymentRecharge`,
    method: 'post',
    data: params,
  });
}
//  充值缴费 下单
export function jsApiPay(params) {
  return request({
    url: `/v3/jsApiPay`,
    method: 'post',
    data: params,
    transformRequest: [function(data) {
      return qs.stringify(data);
    }],
    baseURL: `${paymentapi}`,
  });
}
// 充值缴费--- 用户充值再次下单
// /rechargeOrder/payAgain/{rechargeOrderId}
export function payAgain(params) {
  return request({
    url: `/rechargeOrder/payAgain/${params}`,
    method: 'get',
  });
}

// 充值缴费---缴费记录
export function selectRechargeRecord(wuserNo) {
  return request({
    url: `/rechargeOrder/selectRechargeRecord?wuserNo=${wuserNo}`,
    method: 'get',
  });
}

// 自助抄表列表---列表
export function selfMeterReadInfo(params) {
  return request({
    url: `/selfMeterRead/selfMeterReadInfo`,
    method: 'post',
    data: params,
  });
}
// 上传图片
export function uploadPictureBase64(params) {
  return request({
    url: `/minioPicture/uploadPictureBase64`,
    method: 'post',
    data: params,
  });
}
// 立即抄表---提交
export function readInfo(params) {
  return request({
    url: `/selfMeterRead/readInfo`,
    method: 'post',
    data: params,
  });
}
// 自助抄表列表---抄表记录
export function readRecordList(params) {
  return request({
    url: `/selfMeterRead/readRecordList/${params}`,
    method: 'get',
  });
}
// 我的反馈-处理中的数量
export function selectProcessingCount(params) {
  return request({
    url: `/feedbackHandling/selectProcessingCount`,
    method: 'post',
    data: params,
  });
}
// 我的反馈
export function selectFeedbackHandling(params) {
  return request({
    url: `/feedbackHandling/selectFeedbackHandling`,
    method: 'post',
    data: params,
  });
}
// 我的反馈-新增一条记录
export function addFeedbackHandling(params) {
  return request({
    url: `/feedbackHandling/addFeedbackHandling`,
    method: 'post',
    data: params,
  });
}
// 我的反馈-获取反馈人信息
export function selectFeedBackPersonInfo(params) {
  return request({
    url: `/feedbackHandling/selectFeedBackPersonInfo`,
    method: 'post',
    data: params,
  });
}
// 我的反馈---表單配置--- 获取最新发布版本
export function selectMaxRelease(params) {
  return request({
    url: `/formConfigRelease/selectMaxRelease`,
    method: 'post',
    data: params,
  });
}
// 我的反馈-反馈详情
export function selectFeedbackHandlingDetail(params) {
  return request({
    url: `/feedbackHandling/selectFeedbackHandlingDetail/${params}`,
    method: 'get',
  });
}
// 我的反馈-取消举报
export function cancelReport(params) {
  return request({
    url: `/feedbackHandling/cancelReport/${params}`,
    method: 'get',
  });
}
// 我的反馈---获取类型
export function selectStatusCode(params) {
  return request({
    url: `/feedbackHandling/selectStatusCode`,
    method: 'post',
    data: params,
  });
}
// 我的反馈---获取类型
export function selectBusCode(params) {
  return request({
    url: `/feedbackHandling/selectBusCode`,
    method: 'post',
    data: params,
  });
}
// 我的反馈-修改信息
export function updateFeedbackHandling(params) {
  return request({
    url: `/feedbackHandling/updateFeedbackHandling`,
    method: 'post',
    data: params,
  });
}
// 身份证识别
export function ocrIdCard(params) {
  return request({
    url: `/custRealInfo/ocrIdCard`,
    method: 'post',
    data: params,
  });
}

// 根据code获取系统字典列表
export function selectDictEnumByCode(params) {
  return request({
    url: `/selectDictEnumByCode/${params}`,
    method: 'get',
  });
}

export  function getUrl(url) {
  //  let url = `${URLAPI}/basicinfo/basicFile/viewFile?url=${value}`;
  // return url;
  if (url && url.indexOf("http") === -1) {
    return `${URLAPI}/basicinfo/basicFile/viewFile?url=${url}`;
}
return url;
}
