const prefix = 'wpg_';

function get(name) {
  let _value = null;

  try {
    _value = window.localStorage.getItem(prefix + name);
    _value = _value ? JSON.parse(decodeURI(window.atob(_value))) : null;
  } catch (error) {
    _value = null;
  }
  return _value;
}

function set(name, value) {
  try {
    const _value = value ? window.btoa(encodeURI(JSON.stringify(value))) : null;
    window.localStorage.setItem(prefix + name, _value);
  } catch (error) {
    console.log(name, error);
  }
}

function remove(name) {
  return window.localStorage.removeItem(prefix + name);
}

export default {
  get,
  set,
  remove
};
